import React from "react";
import HelmetContainer from '../../components/Helmet/HelmetContainer';

export const PrivacyPolicy = () => {
    return (
        <>
            <HelmetContainer title="Privacy policy" />
            <section className="main__body__area purple-top-border">
                <div className="container-fluid p-3">
                    <div className="row g-0 justify-content-center">
                        <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                            <div class="main__body__wrap left__right__space">
                                <div class="dashboard__body__wrap">
                                    <h3 class="account__head mb__30">
                                        Meziz Game Show PRIVACY POLICY
                                    </h3>
                                </div>
                                <div className="live__heightlight mb__30">
                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>INTRODUCTION</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                Meziz Game Show Kenya Limited (“Michezobet”, “we”, “us” or “our”) will treat the data you provide us with as private, and shall only use it in accordance with this Privacy Policy. This Privacy Policy describes how we collect and process your personal information through your use of our website (the “site”), mobile application (the “app”) and your online account you open with us (the “Account”), to create your Account profile, to ensure security of the website and app, and to enable you to use the products and the Account (collectively the “Services”). The purpose of this policy is to provide a better understanding of: 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                    <ul>
                                                        <li>Information we collect</li>
                                                        <li>How do we use this information</li>
                                                        <li>How is the information shared</li>
                                                        <li>Your data rights</li>
                                                        <li>How to contact us</li>
                                                        <li>Other useful privacy and data security related matters</li>
                                                    </ul>
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Please take your time to read this policy. It is very important to check our privacy page regularly, for any new updates to the policy. If we make any change that we consider to be important, we will inform you by placing a notice on the relevant service/product and shall contact you using other communication tools at our disposal. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>WHO IS THE DATA CONTROLLER?</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                For the purposes of the Services, MezizBet Kenya LTD (Michezobet) a company registered under the laws of the Republic of Kenya is the data controller of your Personal Data .You can contact us on 0723995999. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>INFORMATION WE COLLECT</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                All references to ‘Personal Data’ in this Privacy Policy refer to any personal information about a natural person which enables that individual to be identified directly or indirectly by reference to the data provided. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                The Personal Data we receive helps us provide, personalise, and continually improve the Services. We use the information to enable you to use the Services, process payments, communicate with you about your Account activity, products, services and promotional offers, update our records and maintain your Account with us, display content, and recommend our products and services that might be of interest to you. We also use this information to improve our Services, prevent, detect or report fraud or abuses of our Services and enable third parties to carry out technical, logistical, or other functions on our behalf. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Information you give us: We collect the following information when you open an Account with us: first name, surname, gender, date of birth, place of birth, postal address, email address, phone number, language, currency, a valid original copy of an official document bearing his names and photographs (passport or ID card). You may give us additional information that qualifies as Personal Data as part of your use of the Account and/or any of the Services. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Cookie Collection: We automatically receive and store certain types of information whenever you interact with our website and mobile app. We utilise cookies to provide a better customer experience and to enable use of access on our portals. For example, like many websites, we use “cookies,” and other unique identifiers and we obtain certain types of information when your Web browser or device accesses our Services. Refer to your Web browser cookies setting options for more information how to manage your cookies settings. Please note that we may not be able to provide you with all the site features and Services functionalities should you choose not to accept the use of certain types of cookies preferences. Please refer to our cookie policy. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Information from other sources: We may receive information about you from other sources (such as from the payment providers or third party identity verification service provider) and add it to your Account information and only according to this Privacy Policy. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                You will also be required to provide login details of your choice, namely a username, password which will be kept by us. We additionally require you to provide us with a payment method and payment details (such as bank transfer, credit card or another acceptable means of payment) in order that you can credit your Account and make the withdrawals. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                You can access a broad range of information about your Account and your interactions with us, including your Personal Data. You may access that information about you by logging into your Account. You also have the right to access your personal data at any time in order to make alterations to any personal information that may have changed or become obsolete. Should you believe that any personal information we hold for you is incorrect, please contact our customer services here. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>HOW DO WE USE THIS INFORMATION</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                We process your Personal information to operate, provide and improve the Services we render for optimal business operations. These purposes include: 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Use of MezizBet products and your Account: We use your Personal Data or information such as your name, email address, phone number to set up your account, verify your identity, process your bets, process payments to and from your Account and communicate with you about gaming activity, products, services, and promotional offers. The above processing is required and necessary to enable us provide our Services to you in accordance with our terms and conditions. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Provide, troubleshoot, and improve the Service: We use your Personal information to provide functionality, analyse performance, fix errors, and improve the ability and effectiveness of the Services. This processing is required for the purpose of our legitimate interests. Recommendations: We may use your Personal information to recommend features, products, and services that might be of interest to you and to identify your preferences. We use your personal information to provide and suggest tailored content to personalise your experiences with our Services. This processing is necessary for the purpose of our legitimate interest in delivering or presenting relevant contents to our customers. Comply with legal and contractual requirements: We are required to utilise and retain personal information for legal and compliance purposes, including but not restricted to prevention, detection, or investigation of a crime; or fraudulent acts. In relation to internal and external audit obligations, information security purposes, and as required to provide such information under applicable law. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Communicate with you: We use your personal information to communicate with you in relation to the Services via different channels (e.g., by phone, email, chat). Advertising: Subject to any preferences you have chosen (where applicable), we may use your personal information to display interest-based ads for features, products, and services that might be of interest to you. During the period of our contractual and legal obligations in relation to your information and unless specifically instructed otherwise by you, for a reasonable period of time after the relationship has ended in order to inform you about our products, services, promotions and special offers which we think may be of interest to you. Except where we use your information for marketing purposes on the basis of your prior written consent and subject to any opt out preferences you inform us in relation to electronic direct marketing communications, we process personal information for marketing purposes as required for the purpose of our legitimate interests in promoting our services and products. Fraud Prevention: We process information to prevent or detect fraud and abuse in order to protect the security of our customers, Michezobet, and others. Risk Management: To provide the services and products for you and for our legitimate purposes, we process personal data to manage and evaluate risks to our business and customers. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                How Long we keep your information: We will keep your information on our database for as long as it is strictly necessary for the purpose of which the information is collected, being the period during which we are contracted to provide you with our services or for such longer periods as required under enabling laws, and also in accordance with tax obligations. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>HOW IS THE INFORMATION SHARED</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                Your personal information may be disclosed or transferred to other organisation, subject to appropriate agreement or consent or legitimate purpose. We shall only share your information with other companies and organisations where there is a legal obligation to provide your information or there is a legitimate requirement to share the information, and we take into consideration and ensure that appropriate checks and suitable measures are in place for any data that is shared is protected in line with Data Protection Regulations in force. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Making payments: Depending on which payment service you use, you may need to disclose your Personal Data or part of it to your payment provider. Note that the payment provider already has your Personal Data to provide you with the payment services and their use of any Personal Data disclosed by us will be regulated by the payment provider’s privacy policy. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Third-parties service providers: We engage with the technology providers, financial institutions, other companies, and individuals to perform functions on our behalf and transactions that you request or authorise. Examples include sending e-mail, analysing data, providing marketing information on our products subject to your consent, processing payment transactions, verifying identity, fraud screening, and providing customer service. They have access to personal information needed to perform their functions, and shall not use it for other purposes and must process the personal information in accordance with this Privacy Policy and as permitted by the applicable data protection legislation. Such third party service providers may be based outside of Kenyain the jurisdictions, including the European Economic Area jurisdictions, that provide adequate levels of protection of personal data. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Advertising and Third-party Analytics providers: We may also use third party analytics providers, including Google, to collect information about the usage of our Services and to enable us to improve how our Services work. Google Analytics and comparable providers use cookies and other similar technologies to collect information about the usage of our Services and to report website trends to us. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Business Transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user or other customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Policy. Also, in the unlikely event that MezizBet or substantially all of its assets are acquired, user information will of course be one of the transferred assets. MezizBet shall only be responsible for its obligations to you in relation to your Personal Data as the data controller under the applicable laws. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Our Services may include third-party advertising and links to other websites and apps. We do not provide any personally identifiable user information to these advertisers or third-party web sites. If you access a page via a link on our site which takes you to an external website, you take time to read through the privacy policy of the new website which you enter. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>YOUR RIGHTS</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                You have various rights in relation to your data; 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right of access to personal data: You have a right to request a copy of the personal information in our records. In the event that you require such information, you can make a request via our Customer Service Page here. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Kindly provide adequate information to identify yourself, and any other relevant information that would assist in processing your request. Such request will be processed and made available to you in the shortest period. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right of rectification of personal data: You have a right to request that we should rectify and correct any personal information that we are processing concerning you which is incorrect. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right of Erasure: You have a right to request the erasure of your personal information where there is no compelling reason to continue processing the information. If you want to exercise this right, please email info@mezizprizegiving.com. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right to withdraw consent: In a situation where we have relied upon your consent to process your personal information, you have the right to withdraw such consent, without limiting the lawfulness of your information in our custody and processed by us in line with any legal obligation or prior to the withdrawal of the consent. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right to restrict the processing of personal data: In some certain instances, you have the right to request that we suspend the processing of your personal information. When the processing of your personal information is restricted, we can opt to store your information, but we may not process it further, subject to processing on compelling legitimate grounds and in connection to legal obligations. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                – Right to data portability: You have a right in certain situations to receive your personal information in which you made available to us in a structured, commonly used and machine readable format. You can also request that we transfer your information to another party, or organisation subject to technical availability. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>HOW TO CONTACT US</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                If you have any questions or inquiries about this notice or you require further information about your data rights, please contact us using the following email addresses: 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                info@mezizprizegiving.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>FILE A COMPLAINT</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                In the event that you are not satisfied in the way we manage your personal data or information, you also have a right to make a complaint to Meziz Game Show Kenya Ltd (Meziz Game Show) at info@mezizprizegiving.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>SECURITY</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                We design our systems with your security and privacy in mind. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                We work to protect the security of your information you provide to our web sites by using a host of security applications and anti-hack measures inclusive of Secure Sockets Layer (SSL), Akamai IP/Geo Firewall and RESTFUL API to encrypt that information during transmission. We maintain physical, electronic and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                It is important for you to take steps to protect against unauthorised access to your account and your password. You should keep your password and any security questions confidential. The security of your Account relies on your protection of your password and security questions. DO NOT SHARE YOUR PASSWORD. If you do share your password with a third party for any reason, the third party may have access to your account and your personal information, and you may be responsible for actions taken using your password. If you believe someone else has obtained access to your password, please change it immediately and also contact us right away. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>PERSONAL DATA RETENTION</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                We keep your Personal Data to enable your continued use of the Service, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Policy, as may be required by law such as for anti-money laundering and counter terrorist financing, tax and accounting purposes, or as otherwise communicated to you. This applies to us retaining your data after you close the Account. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>PROTECTION OF MINORS</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                The online betting and gaming services that we offer are strictly prohibited for persons less than 18 years of age or under the age of legal consent for using online betting and gaming services under the laws of the jurisdiction in which the person is located. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Consequently, minors may not register and play on our Site. We collect proof of identification from our customers as part of our age verification process. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                Any minor who is found to be gambling on MezizBet shall have his/her Account immediately blocked. MezizBet reserves the right to conduct a security review to validate your identity and age. Registration of the Account authorises us to conduct such security reviews supplied by you against third party databases. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>CHANGES TO THIS POLICY</h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your Account. It may become necessary, at any time, for us to make modifications to this Privacy Policy and we will publish such modifications on the website. Continued use of the Services will be considered as your acceptance thereof. Such modifications will not impact your Account registration details unless required to the contrary by law. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                It is the responsibility of each customer to regularly check the General Terms and Conditions and Privacy Policy for updates. 
                                                </div>
                                                <div className="row mb-2 text-white">
                                                The laws of Kenya shall govern this Privacy Policy. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default PrivacyPolicy;
