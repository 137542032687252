import React from 'react'
import Body from './Body'
import HelmetContainer from '../../components/Helmet/HelmetContainer';

export const Faq = () => {
  return (
    <>
        <HelmetContainer title="FAQ" />
        <section className="main__body__area purple-top-border">
            <div className="container-fluid p-3">
                <div className="row g-0 justify-content-center">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                        <Body />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default Faq;