import React from 'react'
import HelmetContainer from '../../components/Helmet/HelmetContainer';

export const Contact = () => {
    return (
        <>
            <HelmetContainer title="Contact Us" />
            <section className="main__body__area purple-top-border">
                <div className="container-fluid p-3">
                    <div className="row g-0 justify-content-center">
                        <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                            <div className="left__right__space">
                                <div className="dashboard__body__wrap">
                                    <h3 className="account__head mb__30">Contact</h3>
                                </div>

                                <div className="live__heightlight mb__30">
                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>phone Number
                                        </h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                    <ul>
                                                        <li>0769333330 <br />0103333332 </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>Email
                                        </h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                    <ul>
                                                        <li>info@mezizprizegiving.com</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section__title">
                                        <h4 style={{ color: "white" }}>Address
                                        </h4>
                                    </div>
                                    <div className="heightlight__tab">
                                        <div
                                            className="nav b__bottom"
                                            id="nav-tabheight"
                                            role="tablist"
                                        >
                                            <div className="container">
                                                <div className="row mb-2 text-white">
                                                    <ul>
                                                        <li>Mvuli Road</li>
                                                        <li>Westland</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact;