import React from "react";
import Body from "./Body";
import HelmetContainer from '../../components/Helmet/HelmetContainer';

export const TermsAndConditions = () => {
  return (
    <>
        <HelmetContainer title="Terms & conditions" />
        <section className="main__body__area purple-top-border">
            <div className="container-fluid p-3">
                <div className="row g-0 justify-content-center">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 d-flex justify-content-center"> {/* Centering the content */}
                        <Body />
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};
export default TermsAndConditions;
