import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from "./components/Footer";
import Home from './pages/Home';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';
// import Results from './pages/Results/Results';
import HowToPlay from './pages/HowToPlay/HowToPlay';
import Contact from './pages/Contact/Contact';
// import Winners from './pages/Winners/Winners';


function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/faq" element={<Faq />} />
                {/* <Route path="/results" element={<Results />} /> */}
                <Route path="/how-to-play" element={<HowToPlay />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Route path="/winners" element={<Winners />} /> */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
