import React from "react";
import { Link } from "react-router-dom";


export const Body = () => {
  return (
    <>
      <div className="main__body__wrap left__right__space">
        <div className="dashboard__body__wrap">
          <h3 className="account__head mb__30">Frequently Asked Questions</h3>
        </div>
        <div className="live__heightlight mb__30">
          <div className="heightlight__tab">
            <div className="nav b__bottom" id="nav-tabheight" role="tablist">
              <div className="container">
                <div className="row mb-2 text-white">
                  <div className="prematch__wrap">
                    <div className="tab-content" id="nav-tabContentpre">
                      <div
                        className="tab-pane fade text-white show active"
                        id="nav-homepre"
                        role="tabpanel"
                        aria-labelledby="nav-home-tabpre"
                        tabIndex="0"
                      >
                        <div className="prematch__scopre this____parent__remove sidebar-livematch">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button "
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  <span className="d-flex align-items-center gap-2 left-chokoboko">
                                    <span className="score text-white">
                                    How do I win Prize Giving Competition
                                    </span>
                                  </span>
                                  <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                    <i className="fa-solid fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p className="text-white">
                                  You win a prize in the Prize Giving Competition and Daily Draws by matching the numbers on your ticket to the numbers drawn for the Prize Giving Competition results. Prizes are received by matching 3 numbers through to the jackpot for matching 6 numbers. You can find the complete prize list on the <a href='/how-to-play'>How to Play page</a> .<br />

                                    You can match numbers in any order. For example: <br />
                                    - Your ticket has the numbers 6, 33, 1, 39, 28, 27 <br />
                                    - The results of the Prize Giving Competition draw are 17, 1, 33, 6, 36, 22 <br />
                                    In this example you would have matched 3 main numbers (1, 6, 33). This would win you x5 of your stake amount. <br />
                                    We also offer more chances to win on the Prize Giving Competition Live show that is broadcast live on K24, Nation TV, Kameme TV, Kass TV, and Magharibi TV at 22:00 on the day of the draw. Please watch the show for details. <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingtwos">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapsetwos"
                                  aria-expanded="true"
                                  aria-controls="collapsetwos"
                                >
                                  <span className="d-flex align-items-center gap-2 left-chokoboko">
                                    <span className="score text-white">
                                    What's the maximum amount I can win?
                                    </span>
                                  </span>
                                  <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                    <i className="fa-solid fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapsetwos"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingtwos"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p className="text-white">
                                  The maximum amount you can win is Ksh 200,000,000 by matching 6 numbers to win x100,000 of maximum stake of Ksh2,000.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingthrees"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapsethrees"
                                  aria-expanded="true"
                                  aria-controls="collapsethrees"
                                >
                                  <span className="d-flex align-items-center gap-2 left-chokoboko">
                                    <span className="score text-white">
                                    How do I get my money?
                                    </span>
                                  </span>
                                  <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                    <i className="fa-solid fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapsethrees"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingthrees"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p className="text-white">
                                  Prizes ranging in value from ‘Ksh100 to Ksh150,000’: Prizes and other winnings from Games in this category will automatically be deposited into Your Mobile Money Account. <br />

                                    Prizes ranging in value from ‘Ksh150,000 and above’ : Prizes and other winnings from Games in this category will be settled via cheque, bank transfer, direct credit. <br />                
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingfour">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapsefour"
                                  aria-expanded="true"
                                  aria-controls="collapsefour"
                                >
                                  <span className="d-flex align-items-center gap-2 left-chokoboko">
                                    <span className="score text-white">
                                    What's the tax deduction?
                                    </span>
                                  </span>
                                  <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                    <i className="fa-solid fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapsefour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingfour"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p className="text-white">
                                  All Prize Giving Competition Prizes are subject to 20% gaming tax deduction.
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfive">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsefive"
                                    aria-expanded="true"
                                    aria-controls="collapsefive"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            How do I pick my own numbers?
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsefive"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingfive"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    You can pick your 6 lucky Numbers from 0-39 and use them to play as follows. <br />

                                    <b>SMS</b> – Send your 6 numbers (separated by comma or space) e.g. 1,11,21,31,2,3 or 1 11 21 31 2 3 to <b>382777</b>. Enter Mpesa PIN to complete charge request. <br />

                                    <b>Mpesa Paybill</b> – Send Ksh 50-2,000 to Paybill Number 777000. Enter your 6 numbers (separated by comma or space) as the account number. e.g. 1,11,21,31,2,3 or 1 11 21 31 2 3. Enter Mpesa PIN to complete transaction. <br />

                                    <b>Web</b> – visit www.mezizprizegiving.com/. Enter Mobile Number, input 6 Lucky numbers from 0-39 or click Random Picks to auto-pick the numbers. Enter amount between Ksh50-2,000. Click PLAY NOW. Enter Mpesa PIN to complete charge request. <br />
                                    </p>
                                </div>
                              </div>
                            </div> */}

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingsix">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsesix"
                                    aria-expanded="true"
                                    aria-controls="collapsesix"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            When are draws conducted?
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsesix"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingsix"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    Daily draws are hourly on Monday, Tuesday, Thursday, Friday and Sunday. <br />

                                    Main Draws are run every Wednesday and Saturday and are televised live on K24, Nation TV, Kameme TV, Kass TV, and Magharibi TV at 10pm.
                                    </p>
                                </div>
                              </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingseven">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseseven"
                                    aria-expanded="true"
                                    aria-controls="collapseseven"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            How many times can I play in a day?
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseseven"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingseven"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    You can play as many times as you wish. We however advocate for responsible gaming. <br />

                                    Where can I find <a href='/results'>the results and winners?</a><br />

                                    Main Draws: On Wednesday and Saturday, the results of the Prize Giving Competition draw are first broadcast on the Prize Giving Competition Live show on K24, Nation TV, Kameme TV, Kass TV, and Magharibi TV at 22:00 on the day of the draw. They are published on the website at 22:15 on the day of the draw on the <a href='/results'>Results page </a>. The number of winners of each prize level and their details will appear on this page. <br />

                                    Daily Draws: All daily draw results will be published on the <a href='/results'>Results page </a> immediately after the hourly draws on Monday, Tuesday, Thursday, Friday and Sunday. <br />
                                    </p>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingeigth">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseigth"
                                    aria-expanded="true"
                                    aria-controls="collapseigth"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            How are the Prize Giving Competition numbers drawn? Is Prize Giving Competition regulated? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseigth"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingeigth"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    Prize Giving Competition draws use a Random Number Generator that has been tested and certified by <Link to={{  pathname: 'https://gaminglabs.com/gli-certified-mark/' }}  target="_blank">Gaming Laboratories International (GLI)</Link>. GLI is a global leader in the provision of testing and certification services to 65 of the world’s leading lotteries and is trusted by regulators in more the 475 jurisdictions worldwide. Prize Giving Competition operates under license number 0000898 issued by the Kenya Betting Control and Licensing Board (BCLB) who conduct each Prize Giving Competition draw. This means that you can play with 100% confidence in the Prize Giving Competition draw process and result. <br />

                                    What is the draw process? Can the draw process be tampered with? <br />

                                    The Prize Giving Competition draw is closed to all entries at 9pm on both Wednesday and Saturday. After the draw has closed, all entries are collated into a single file, generating a 256bit "Hash key" (like a fingerprint). The Hash key and the entire entry file (comprising of all tickets entered into the draw) are passed to the BCLB and to the Insurance underwriters before the Random Numbers are drawn. This ensures that it is impossible to tamper with any of the entries after the Random Number selection as any alteration of the entries would result in the Hash key not matching. <br />

                                    The process happens at approximately 21:00. Once the integrity of the Winning Numbers are verified and checked by the BLCB, the winning numbers are entered into the computer graphics system of Prize Giving Competition Live for display on the TV Show.
                                    </p>
                                </div>
                              </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingnine">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsenine"
                                    aria-expanded="true"
                                    aria-controls="collapsenine"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            How are the Prize Giving Competition Live winners picked? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsenine"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingnine"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    After the drawing of the winning numbers for the main Prize Giving Competition draw has taken place, a predetermined number of tickets are picked at random for the Prize Giving Competition Live raffle draws. These Ticket Numbers are selected from all entries into the draw that has just closed. This process is conducted by the representative of the BCLB. The holders of these selected Ticket Numbers are called for participation in the Prize Giving Competition Live show about to go on air, in order to make sure they are available to be on call during the show. The winning Ticket Numbers are displayed on the TV show. The ticket holders are then called back during the Prize Giving Competition Live show, where they are given extra chances to win. At selected times, for instance if we are unable to reach a caller, additional random users may be selected by the BCLB and called during the live show. A record is kept of all the Ticket Numbers and each one of them, whether called or not, automatically receive a default prize of Ksh 1000. <br />

                                    How does the Second Chance Lucky Draw game on Prize Giving Competition Live work? <br />

                                    The nine boxes of the Second Chance Lucky Draw game are filled with varying prize levels by the representative of the BCLB before the start of the show, in the company of only one representative of Prize Giving Competition, and a record is kept of the positions of each value. No other individuals are present during this procedure. <br />

                                    Some of the callers selected to go on air are automatically given a prize of Ksh 50,000, and then given the chance to play Fortune of Fate. At this point, the caller may choose not to play, in which case they keep their winnings of Ksh 50,000. If they choose to play, they forfeit the Ksh 20,000 at that point, but must then pick a number between 1 and 9. When they pick the number, the presenter of the show will open the box corresponding to the number chosen. The boxes contain a variety of prize amounts and the amount won is revealed by the present of the show. <br />

                                    How do I know I've won? <br />

                                    When you win a prize in any Prize Giving Competition draw, you will be notified via SMS and your winnings paid directly into your Mpesa. Payments of over Ksh 150,000 are made by cheque or bank transfer. <br />

                                    I won on Prize Giving Competition Live, how am I paid? <br />

                                    A member of the Prize Giving Competition Live team will call all of the winners from the show shortly after it has finished, and give details on how they will receive their payments. Payments of over Ksh 150,000 are made by cheque or wire transfer. Payments of Ksh 150,000 or less are paid directly into your Mpesa. This is a manual process that can take up to 3 working days but is normally much quicker. If you feel you have waited too long, please contact our Customer Support Team on 0703065032 and they will be happy to assist you. <br />

                                    How much does it cost to play? Can I buy more than one ticket? <br />

                                    Prize Giving Competition tickets range from Ksh50-2,000. Yes, you can buy more than one ticket. <br />

                                    I bought a ticket, where can I see it? <br />

                                    After purchasing a ticket through SMS, Mpesa or Web, you will receive an SMS from 79007 with your ticket information. <br />

                                    What time does the draw close? <br />

                                    Results of the draw are first broadcast on the Prize Giving Competition Live show on K24, Nation TV, Kameme TV, Kass TV, and Magharibi TV at 22:00 on Wednesdays and Saturdays, however the draw entries close at 21:00. <br />
                                    There is a countdown at the top of every page on the website indicating the deadline for entries. Any entries received or processed once the draw is closed will be entered in to the following draw. Your ticket confirmation will confirm the date and time of the draw you have been entered into. <br />

                                    What are the draw times? <br />

                                    Prize Giving Competition has two Main draws a week: <br />

                                    - Wednesday: 21:00 <br />
                                    - Saturday: 21:00 <br />

                                    Deadline for entries into the draw is 20:59:59 on the day of the draw. Entries received after this time will be entered into the next available draw. <br />

                                    The results of the Prize Giving Competition draw are first broadcast on the Prize Giving Competition Live show on K24, Nation TV, Kameme TV, Kass TV, and Magharibi TV at 22:00 on the day of the draw. They are published on the website at 22:15 on the day of the draw on the <a href='/results'>Results page</a> . For more details on the draw process, please see the question What is the draw process? Can the draw process be tampered with? above. <br />

                                    Daily Draws happen hourly on Monday, Tuesday, Thursday, Friday and Sunday. <br />

                                    Do I to register to play? <br />

                                    No you don’t need to register to play. Once enter any Prize Giving Competition Draw via SMS, Mpesa or Web you’re good to go.  <br />

                                    I don’t want to receive any marketing messages? <br />

                                    To stop receiving any further special offers and promotions, please call our Customer Support Team on 0703065032 and they will be happy to assist you. <br />
                                    </p>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingten">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseten"
                                    aria-expanded="true"
                                    aria-controls="collapseten"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            Can I play Prize Giving Competition if I don’t live in Kenya? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseten"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingten"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    No. In order to play the Prize Giving Competition you have to be physically based in Kenya and have a verified Mpesa Account.
                                    </p>
                                </div>
                              </div>
                            </div>



                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingeleven">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseeleven"
                                    aria-expanded="true"
                                    aria-controls="collapseeleven"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            How old do I have to be to play Prize Giving Competition? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseeleven"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingeleven"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    You need to be 18 years or older to play Prize Giving Competition.
                                    </p>
                                </div>
                              </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingtwelve">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsetwelve"
                                    aria-expanded="true"
                                    aria-controls="collapsetwelve"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            Can I play only using SMS? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsetwelve"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingtwelve"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    Yes you can play Prize Giving Competition by SMS only. For details on how <a href='/how-to-play'>to play click here</a> .
                                    </p>
                                </div>
                              </div>
                            </div>



                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingthirteen">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsethirteen"
                                    aria-expanded="true"
                                    aria-controls="collapsethirteen"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            I sent an SMS ticket request but I didn’t receive a ticket back? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsethirteen"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingthirteen"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    If you have waited longer than 15 minutes and still not received your ticket please call our Customer Support Team on 0703065032 and they will be happy to assist you.
                                    </p>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingtourteen">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsetourteen"
                                    aria-expanded="true"
                                    aria-controls="collapsetourteen"
                                    >
                                        <span className="d-flex align-items-center gap-2 left-chokoboko">
                                            <span className="score text-white">
                                            I play via Web. Can I play via SMS too? 
                                            </span>
                                        </span>
                                        <span className="d-flex align-items-center gap-1 icon-rightfs10">
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsetourteen"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingtourteen"
                                    data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body">
                                    <p className="text-white">
                                    Yes, if you have been playing Online previously you can still purchase tickets via SMS. Please visit the <a href='/how-to-play'>How to Play page</a> for more information.
                                    </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-5 text-center">All winnings on Prize Giving Competition are subject to 20% Tax.</h2>
      </div>
    </>
  );
};
export default Body;
