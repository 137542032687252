import React from 'react'
import HelmetContainer from '../../components/Helmet/HelmetContainer';


export const HowToPlay = () => {
  return (
    <>
        <HelmetContainer title="How to play" />
        <section className="main__body__area purple-top-border">
            <div className="container-fluid p-3">
                <div className="row g-0 justify-content-center">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                        <div className="left__right__space">
                            <div className="dashboard__body__wrap">
                                <h3 className="account__head mb__30">How to play</h3>
                            </div>

                            <div className="live__heightlight mb__30">
                                <div className="section__title">
                                    <h4 style={{ color: "white" }}>2 Simple ways to play Meziz Game Show
                                    </h4>
                                </div>
                                <div className="heightlight__tab">
                                    <div
                                        className="nav b__bottom"
                                        id="nav-tabheight"
                                        role="tablist"
                                    >
                                        <div className="container">
                                            <div className="row mb-2 text-white">
                                                <ul>
                                                    <li>1. Click on the draw of you choice on homepage directly at the CHEZA button </li>
                                                    <li>2. ⁠Enter your phone number then click on play now</li>
                                                    <li>3. ⁠Wait for mpesa prompt in your phone</li>
                                                    <li>4. ⁠Enter mpesa pin</li>
                                                    <li>5. ⁠You will get a ticket on your phone</li>
                                                    <li>6. ⁠Be patient and wait for the draw and check if your ticket will be among the lucky winners</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section__title">
                                    <h4 style={{ color: "white" }}>2nd way for daily draws
                                    </h4>
                                </div>
                                <div className="heightlight__tab">
                                    <div
                                        className="nav b__bottom"
                                        id="nav-tabheight"
                                        role="tablist"
                                    >
                                        <div className="container">
                                            <div className="row mb-2 text-white">
                                                <ul>
                                                    <li>1. Sms WEB to 24870</li>
                                                    <li>2. ⁠Input mpesa pin to deposit money for playing</li>
                                                    <li>3. ⁠Get your ticket</li>
                                                    <li>4. ⁠Be patient and wait for the draw and check if your ticket will be among the lucky winners</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default HowToPlay;